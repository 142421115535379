<template>
  <v-container
    grid-list-xl
    fluid
    style="padding-top: 0;">
    <v-layout wrap>
      <v-flex md12>
        <material-card
          :title="$t('portal.emailDomain.activeKey')"
          color="green"
          flat
          full-width
          class="bg-white"
        >
          <v-form
            ref="form"
            v-model="valid">
            <v-container py-0>
              <v-layout wrap>
                <v-flex
                  xs12
                  sm8
                  md6
                >
                  <v-text-field
                    :rules="[ruleRequiredValue]"
                    v-model="activeKeyCode"
                    :label="$t('portal.emailDomain.activeKey') "
                    :name="$t('portal.emailDomain.activeKey')"
                    prepend-icon="mdi-account"
                    type="text"
                    class="required"
                  />
                </v-flex>
              </v-layout>
              <v-btn
                style="margin-right: 0;"
                class="pull-right"
                color="success"
                @click="onCreateActiveKey"
              >
                <span style="text-transform: none;">{{
                  $t("common.save")
                }}</span>
              </v-btn>
            </v-container>
          </v-form>
        </material-card>
        <material-card
          :title="$t('portal.emailDomain.domainList')"
          color="green"
          flat
          full-width
        >
          <div class="py-4">
            <tab-header
              :tab-items="domainTabType"
              @onSelectTab="onSelectDomainType" />
          </div>
          <template>
            <v-btn
              v-if="isExist && domainTypeSelected == domainType.DOMAIN_EMAIL"
              color="success"
              dark
              style="float: right;"
              @click="onShowModalAddEmailDomain(null, domainType.DOMAIN_EMAIL)"
            >
              <v-icon class="mr-2">mdi-email-plus-outline</v-icon>
              {{ $t('portal.emailDomain.addNewEmailDomain') }}
            </v-btn>
            <v-btn
              v-if="isExist && domainTypeSelected == domainType.USER_EMAIL"
              color="success"
              dark
              style="float: right;"
              @click="onShowModalAddEmailDomain(null, domainType.USER_EMAIL)"
            >
              <v-icon class="mr-2">mdi-account-plus-outline</v-icon>
              {{ $t('portal.emailDomain.addNewUserDomain') }}
            </v-btn>
            <v-btn
              v-if="isExist"
              color="success"
              dark
              style="float: right;"
              @click="onShowModalImportExcel"
            >
              <v-icon class="mr-2">mdi-microsoft-excel</v-icon>
              {{ $t('portal.emailDomain.importExcel') }}
            </v-btn>
            <!-- Download template voucher -->
            <a
              :href="domainPath + `/templates/import_user_domain_email.xlsx`"
              type="button"
              style="float: right;"
              class="v-btn theme--dark warning"
              download>
              <v-icon
                class="mr-2"
                color="white">mdi-download</v-icon> <span style="text-transform: none;">{{ $t('common.downloadTemplate') }}</span>
            </a>
          </template>
          <v-data-table
            :no-data-text="$t('common.noDataAvailable')"
            :headers="domainHeader"
            :items="domainList"
            :single-select="true"
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="text--darken-3 font-medium"
                v-text="$t(header.text)"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }"
            >
              <td>{{ item.stt }}</td>
              <td>{{ item.emailDomain }}</td>
              <td>{{ item.name }}</td>
              <td class="text-xs-center">
                <v-menu
                  v-model="item.selectedButton"
                  offset-y
                  transition="slide-y-transition"
                  bottom
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      color="indigo"
                      dark
                      v-on="on"
                    >
                      <v-icon>mdi-dots-horizontal</v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <!-- Update supplier -->
                    <v-tooltip
                      top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          class="mx-2"
                          fab
                          small
                          color="primary"
                          style="color: #ffffff;"
                          v-on="on"
                          @click="onShowModalAddEmailDomain(item.id, item.type)">
                          <v-icon dark>mdi-square-edit-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('common.update') }}</span>
                    </v-tooltip>
                    <!-- Delete supplier -->
                    <v-tooltip
                      top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          class="mx-2"
                          fab
                          small
                          color="red"
                          style="color: #ffffff;"
                          v-on="on"
                          @click="onShowModalConfirmDeleteDomain(item.id)">
                          <v-icon dark>mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('common.delete') }}</span>
                    </v-tooltip>
                  </v-card>
                </v-menu>
              </td>
            </template>
          </v-data-table>
          <pagination
            :total="domainPaginate.totalPage"
            :current-page="domainPaginate.currentPage"
            :row-per-page="domainPaginate.rowPerPage"
            @onPageChange="onPageChange"
          />
        </material-card>
      </v-flex>
    </v-layout>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar" />
    <addNewEmailDomainModal
      ref="addNewEmailDomainModal"
      @createDomainSuccess="onCreateDomainSuccess" />
    <confirmModal
      ref="confirmModal"
      :title="confirmModalTitle"
      @onConfirm="onDeleteDomain"/>
    <importExcelEmailDomain
      ref="importExcelEmailDomain"
      @success="onImportSuccess" />
    <loadingBar :is-loading="isLoading" />
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ToastType from 'enum/toastType'
import AddNewEmailDomainModal from './AddNewEmailDomainModal.vue'
import ConfirmModal from 'Components/ConfirmModal'
import functionUtils from 'utils/functionUtils'
import validationRules from 'utils/validationRules'
import DomainType from 'enum/domainType'
import ImportExcelEmailDomain from './ImportExcelEmailDomain.vue'
import stringUtils from 'utils/stringUtils'
import TabHeader from 'Components/TabHeader.vue'
export default {
  components: {
    AddNewEmailDomainModal,
    ConfirmModal,
    ImportExcelEmailDomain,
    TabHeader
  },
  data: () => ({
    domainTabType: [
      {
        title: 'Domain email',
        id: DomainType.DOMAIN_EMAIL
      },
      {
        title: 'User email',
        id: DomainType.USER_EMAIL
      }
    ],
    domainHeader: [
      {
        sortable: false,
        text: 'common.nonumber',
        value: 'stt'
      },
      {
        sortable: false,
        text: 'Domain email',
        value: 'emailDomain'
      },
      {
        sortable: false,
        text: 'Domain name',
        value: 'name'
      },
      {
        sortable: false,
        text: 'common.actions',
        value: 'actions',
        align: 'center'
      }
    ],
    domainList: [],
    activeKeyCode: null,
    isExist: false,
    activeKeyId: null,
    domainPaginate: {
      totalPage: 1,
      currentPage: 1,
      rowPerPage: 1
    },
    domainId: null,
    confirmModalTitle: null,
    isLoading: false,
    valid: false,
    domainType: {
      DOMAIN_EMAIL: DomainType.DOMAIN_EMAIL,
      USER_EMAIL: DomainType.USER_EMAIL
    },
    domainTypeSelected: DomainType.DOMAIN_EMAIL,
    domainPath: stringUtils.DOMAIN_BASE_PATH_ADMIN
  }),
  computed: {
    ...mapGetters([
      'GET_SNACK_BAR'
    ])
  },
  watch: {
  },
  created () {
    this.getActiveKey()
  },
  methods: {
    /**
     * Import success
     */
    onImportSuccess: function () {
      this.getDomain()
    },
    /**
     * Show modal import excel
     */
    onShowModalImportExcel: function () {
      this.$refs.importExcelEmailDomain.onShowModal(this.activeKeyId)
    },
    /**
     * Select domain type
     */
    onSelectDomainType: function ({ index, item }) {
      this.domainTypeSelected = item.id
      this.getDomain()
      for (let i = 0, size = this.domainHeader.length; i < size; i++) {
        if (this.domainTypeSelected === DomainType.USER_EMAIL) {
          if (this.domainHeader[i].value === 'emailDomain') {
            this.domainHeader[i].text = 'User email'
          }
          if (this.domainHeader[i].value === 'name') {
            this.domainHeader[i].text = 'User name'
          }
        } else {
          if (this.domainHeader[i].value === 'emailDomain') {
            this.domainHeader[i].text = 'Domain email'
          }
          if (this.domainHeader[i].value === 'name') {
            this.domainHeader[i].text = 'Domain name'
          }
        }
      }
    },
    ruleRequiredValue (value) {
      return validationRules.ruleRequiredValue(value)
    },
    /**
     * Delete domain
     */
    onDeleteDomain: function () {
      this.DELETE_DOMAIN({ id: this.domainId }).then(
        function () {
          this.getDomain()
        }.bind(this)
      )
    },
    /**
     * Show modal confirm delete domain
     */
    onShowModalConfirmDeleteDomain: function (id) {
      this.domainId = id
      this.confirmModalTitle = 'Bạn có chắc chắn muốn xoá domain này?'
      this.$refs.confirmModal.onShowModal()
    },
    /**
     * Page change
     */
    onPageChange: function (page) {
      this.domainPaginate.currentPage = page
      this.getDomain()
    },
    onShowModalAddEmailDomain: function (domainId, type) {
      this.$refs.addNewEmailDomainModal.onShowModal(domainId, this.activeKeyId, type)
    },
    /**
     * Create domain success
     */
    onCreateDomainSuccess: function () {
      this.ON_SHOW_TOAST({
        message: this.$t('common.success'),
        styleType: ToastType.SUCCESS
      })
      this.getDomain()
    },
    /**
     * Get domain
     */
    getDomain: function () {
      let filter = {
        params: {
          activeKeyId: this.activeKeyId,
          page: this.domainPaginate.currentPage,
          type: this.domainTypeSelected
        }
      }
      this.isLoading = true
      this.GET_DOMAIN(filter).then(
        function (res) {
          this.isLoading = false
          let data = res.data
          // Handle paginate
          this.domainPaginate.currentPage = data.page
          this.domainPaginate.totalPage = data.num_pages
          this.domainPaginate.rowPerPage = data.per_page / 2
          this.domainList = []
          for (let i = 0, size = data.results.length; i < size; i++) {
            let domainObj = {
              stt: i + 1 + (data.page - 1) * data.page_size,
              id: data.results[i].id,
              emailDomain: data.results[i].domain,
              name: data.results[i].name,
              type: data.results[i].type
            }
            this.domainList.push(domainObj)
          }
        }.bind(this)
      ).catch(
        function () {
          this.isLoading = false
        }.bind(this)
      )
    },
    /**
     * Get active key
     */
    getActiveKey: function () {
      this.GET_ACTIVE_KEY().then(
        function (res) {
          let data = res.data.data
          this.isExist = res.data.isExist
          this.activeKeyCode = data.code
          this.activeKeyId = data.id
          this.getDomain()
        }.bind(this)
      )
    },
    /**
     * Create active key
     */
    onCreateActiveKey: function () {
      if (this.$refs.form.validate()) {
        let filter = {
          code: this.activeKeyCode
        }
        this.CREATE_ACTIVE_KEY(filter).then(
          function (res) {
            this.ON_SHOW_TOAST({
              message: this.$t('common.success'),
              styleType: ToastType.SUCCESS
            })
            this.getActiveKey()
          }.bind(this)
        ).catch(
          function (error) {
            if (!functionUtils.isNull(error.response.data.errorText)) {
              let errorText = error.response.data.errorText
              this.ON_SHOW_TOAST({
                'message': this.$t(errorText),
                'styleType': ToastType.ERROR
              })
            } else {
              this.ON_SHOW_TOAST({
                'message': this.$t('login.somethingWentWrong'),
                'styleType': ToastType.ERROR
              })
            }
          }.bind(this)
        )
      }
    },
    ...mapActions([
      'ON_SHOW_TOAST',
      'CREATE_ACTIVE_KEY',
      'GET_ACTIVE_KEY',
      'GET_DOMAIN',
      'DELETE_DOMAIN'
    ])
  }
}
</script>

<style lang="scss" scoped>
.banner-img-class {
  width: 80%;
  cursor: pointer;
}
input[type="file"] {
  display: none;
}
.remove-banner-img {
  color: #ef5350;
  cursor: pointer;
}
.remove-banner-img:hover {
  text-decoration: underline;
}
/deep/.color-review {
  width: 100%;
  height: 305px;
  max-height: 305px;
  border: 1px solid #ddd;
  border-radius: 4px;
  caret-color: auto;
  &:hover {
    box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
  }
}
/deep/.required label::after {
  content: " *";
  color: red;
}
.cursor-pointer {
  cursor: pointer;
}
</style>
