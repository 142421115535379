<template>
  <v-dialog
    v-model="isShowModal"
    persistent
    max-width="800px">
    <v-card>
      <material-card
        :title="domainId ? (domainTypeSelected == domainType.DOMAIN_EMAIL ? $t('portal.emailDomain.update') : $t('portal.emailUser.update')) : (domainTypeSelected == domainType.DOMAIN_EMAIL ? $t('portal.emailDomain.add') : $t('portal.emailUser.add'))"
        tile
        color="green"
        full-width
      >
        <v-container grid-list-xl>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation>
            <v-layout wrap>
              <!-- Email -->
              <v-flex
                xs12
                md6>
                <v-text-field
                  :rules="[ruleRequiredValue]"
                  v-model="emailDomain"
                  :label="domainTypeSelected == domainType.DOMAIN_EMAIL ? 'Domain email' : 'User email'"
                  :name="domainTypeSelected == domainType.DOMAIN_EMAIL ? 'Domain email' : 'User email'"
                  type="text"
                  class="required"
                />
              </v-flex>
              <!-- Name -->
              <v-flex
                xs12
                md6>
                <v-text-field
                  v-model="name"
                  :label="domainTypeSelected == domainType.DOMAIN_EMAIL ? 'Domain name' : 'User name'"
                  :name="domainTypeSelected == domainType.DOMAIN_EMAIL ? 'Domain name' : 'User name'"
                  type="text"
                />
              </v-flex>
            </v-layout>
          </v-form>
        </v-container>
      </material-card>
      <v-card-actions>
        <v-spacer />
        <v-btn
          small
          color="error darken-1"
          @click="onCloseModal"
        >{{ $t("common.close") }}
        </v-btn>
        <v-btn
          small
          color="success darken-1"
          @click="onConfirm"
        >{{ $t("common.confirm") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"
    />
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ToastType from 'enum/toastType'
import functionUtils from 'utils/functionUtils'
import validationRules from 'utils/validationRules'
import DomainType from 'enum/domainType'
export default {
  data: () => ({
    emailDomain: null,
    isShowModal: false,
    activeKeyId: null,
    domainId: null,
    valid: false,
    domainType: {
      DOMAIN_EMAIL: DomainType.DOMAIN_EMAIL,
      USER_EMAIL: DomainType.USER_EMAIL
    },
    name: null,
    domainTypeSelected: null
  }),
  computed: {
    ...mapGetters([
      'GET_SNACK_BAR'
    ])
  },
  methods: {
    ruleRequiredValue (value) {
      return validationRules.ruleRequiredValue(value)
    },
    /**
     * Confirm
     */
    onConfirm: function () {
      if (this.$refs.form.validate()) {
        let filter = {
          activeKeyId: this.activeKeyId,
          domain: this.emailDomain,
          name: this.name,
          type: this.domainTypeSelected
        }
        if (this.domainId) {
          this.UPDATE_DOMAIN({ id: this.domainId, ...filter })
            .then(
              function () {
                this.$emit('createDomainSuccess')
                this.isShowModal = false
              }.bind(this)
            )
            .catch(
              function (error) {
                if (!functionUtils.isNull(error.response.data.errorText)) {
                  let errorText = error.response.data.errorText
                  this.ON_SHOW_TOAST({
                    message: this.$t(errorText),
                    styleType: ToastType.ERROR
                  })
                } else {
                  this.ON_SHOW_TOAST({
                    message: this.$t('login.somethingWentWrong'),
                    styleType: ToastType.ERROR
                  })
                }
              }.bind(this)
            )
        } else {
          this.CREATE_DOMAIN(filter)
            .then(
              function () {
                this.$emit('createDomainSuccess')
                this.isShowModal = false
              }.bind(this)
            )
            .catch(
              function (error) {
                if (!functionUtils.isNull(error.response.data.errorText)) {
                  let errorText = error.response.data.errorText
                  this.ON_SHOW_TOAST({
                    message: this.$t(errorText),
                    styleType: ToastType.ERROR
                  })
                } else {
                  this.ON_SHOW_TOAST({
                    message: this.$t('login.somethingWentWrong'),
                    styleType: ToastType.ERROR
                  })
                }
              }.bind(this)
            )
        }
      }
    },
    /**
     * Show modal
     */
    onShowModal: function (domainId, activeKeyId, type) {
      this.domainTypeSelected = type
      this.domainId = domainId
      if (this.domainId) {
        this.GET_DOMAIN_DETAIL({ id: this.domainId }).then(
          function (res) {
            let data = res.data
            this.emailDomain = data.domain
            this.name = data.name
          }.bind(this)
        )
      }
      this.activeKeyId = null
      this.activeKeyId = activeKeyId
      this.emailDomain = null
      this.isShowModal = true
      this.name = null
    },
    /**
     * Close modal
     */
    onCloseModal: function () {
      this.isShowModal = false
    },
    ...mapActions([
      'CREATE_DOMAIN',
      'GET_DOMAIN_DETAIL',
      'UPDATE_DOMAIN',
      'ON_SHOW_TOAST'
    ])
  }
}
</script>

<style lang="scss" scoped>
.modal {
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.v-card {
  margin-bottom: 0px !important;
  margin-top: 21px !important;
}
/deep/.required label::after {
  content: " *";
  color: red;
}
</style>
